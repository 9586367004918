<template>
  <NavBar />
</template>

<script>
  import NavBar from '../components/NavBar.vue'

  export default {
    name: 'FederateView',
    components: {
      NavBar
    }
  }
</script>
