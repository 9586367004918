import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap"
import * as Vue from 'vue'
import * as VueRouter from 'vue-router'

import App from './App.vue'
import AboutView from './views/About.vue'
import BrowseView from './views/Browse.vue'
import FederateView from './views/Federate.vue'

const routes = [
  { 
    path: '/',
    name: 'Browse', 
    component: BrowseView
  },
  {
    path: '/federate',
    name: 'Federate',
    component: FederateView
  },
  { 
    path: '/about',
    name: 'About',
    component: AboutView 
  },
]

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  linkActiveClass: "active",
  routes,
})

Vue.createApp(App).use(router).mount('#app')
