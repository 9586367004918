<template>
  <div class="container mt-4">
    <div class="row">
      <div class="col col-12 col-sm-3 order-sm-2 mb-4">
        <ul class="list-group">
          <li class="list-group-item" style="background-color: var(--bs-blue); color: var(--bs-white);">About</li>
          <li class="list-group-item">What is PopularFish?</li>
          <li class="list-group-item">Why did you build it?</li>
          <li class="list-group-item">How do I use it?</li>
          <li class="list-group-item">What is your Privacy Policy?</li>
        </ul>
      </div>
      <div class="col col-12 col-sm-9 order-sm-1">
        <h3>What is PopularFish?</h3>
        <p>PopularFish is a discovery tool that highlights popular content in the Fediverse. There's no real secret sauce, recommendation engine, or AI behind it. All it does is gather trending toots from popular, listed Mastodon instances.</p>
        <p>Specifically, behind the scenes:</p>
        <ul>
          <li>A crawler goes through the list of public Mastodon servers registered at <a href="https://joinmastodon.org/servers">joinmastodon.org/servers</a> approximately once an hour.</li>
          <li>The crawler retrieves the top 40 statuses from the public <code>/api/v1/trends/statuses</code> endpoint on each server.</li>
          <li>Retrieved posts are deduplicated (since the same post could be trending on multiple instances.)</li>
          <li>Finally, newly-seen posts are forwarded via ActivityPub to instances that have set up PopularFish as a relay.</li>
        </ul>
        <h3>Why did you build it?</h3>
        <p>As the administrator of a very small Mastodon instance, I wanted my Federated timeline to look a little bit less... sparse. After checking out a number of Mastodon relays, I realized that I <em>definitely</em> didn't want a full firehose of toots to scroll through, but I <em>did</em> want to see content from more folks than just the people I follow, similar to the Explore page on a larger instance like mastodon.social.</p>
        <h3>How do I use it?</h3>
        <p><strong>If you're the administrator of a Mastodon instance,</strong> just follow the instructions on the <router-link to="/federate">Federate</router-link> page to set up PopularFish as a one-way relay. Popular posts from the Fediverse will be sent to your server, but public updates sent by your server to the relay will be ignored and not forwarded (unless, of course, you run a listed server and someone posts something trending that gets crawled, but if that's your scenario you probably don't need any help populating the Federated timeline for your users.)</p>
        <p><strong>If you're a Mastodon user,</strong> feel free to browse! There's no infinite scrolling or real-time content refreshing though, so you may want to ask your instance administrator about adding PopularFish as a relay rather than coming back here once an hour to see what has changed.</p>
        <h3>What is your Privacy Policy?</h3>
        <p></p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AboutView',
  }
</script>
